<template>
  <div class="source_detail">
    <div class="title">
      <h2>
        Detail
        <span style="font-size:26px;">
          SourceId:
          <span>{{ sourceId }}</span>
        </span>
        <span style="font-size:26px;">
          SourceName:
          <span>{{ sourceName }}</span>
        </span>
      </h2>
    </div>
    <div>
      <el-card>
        <el-tabs type="border-card" v-model="activeName">
          <el-tab-pane label="Information" name="first" lazy>
            <information :id="sourceId" />
          </el-tab-pane>
          <el-tab-pane label="System Setting" name="second" lazy>
            <system-setting :id="sourceId" />
          </el-tab-pane>
          <el-tab-pane label="Adjust Rate Setting" name="third" lazy>
            <adjust-rate-setting :id="sourceId" />
          </el-tab-pane>
          <el-tab-pane label="Affiliate WhiteList Message" name="fourth" lazy>
            <aff-white-list :id="sourceId" />
          </el-tab-pane>
          <el-tab-pane
            label="Allow Access Affiliate Message （Not in affiliate blacklist）"
            name="fifth"
            lazy
          >
            <allow-access-aff :id="sourceId" lazy />
          </el-tab-pane>
          <el-tab-pane label="AffSub WhiteList Message" name="sixth" lazy>
            <aff-sub-white-list :id="sourceId" />
          </el-tab-pane>
          <el-tab-pane label="AffSub Blacklist Message" name="eighth" lazy>
            <aff-sub-blacklist :id="sourceId" />
          </el-tab-pane>
          <el-tab-pane label="Affiliate Blacklist Message" name="ninth" lazy>
            <aff-blacklist :id="sourceId" />
          </el-tab-pane>
          <el-tab-pane label="Track Sampling" name="tenth" lazy>
            <track-sampling :id="sourceId" />
          </el-tab-pane>
        </el-tabs>
      </el-card>
    </div>
  </div>
</template>
<script>
  import { myMixin } from '@/mixins/mixins.js';
  import Information from './components/Information';
  import SystemSetting from './components/SystemSetting';
  import AdjustRateSetting from './components/AdjustRateSetting';
  import AffWhiteList from './components/AffWhiteList';
  import AllowAccessAff from './components/AllowAccessAff';
  import AffSubWhiteList from './components/AffSubWhiteList';
  import AffSubBlacklist from './components/AffSubBlacklist';
  import AffBlacklist from './components/AffBlacklist';
  import TrackSampling from './components/TrackSampling';

  export default {
    mixins: [myMixin],
    components: {
      Information,
      SystemSetting,
      AdjustRateSetting,
      AffWhiteList,
      AllowAccessAff,
      AffSubWhiteList,
      AffSubBlacklist,
      AffBlacklist,
      TrackSampling,
    },
    data() {
      return {
        activeName: 'first',
        sourceId: this.$route.query.sourceId,
        sourceName: this.$route.query.sourceName,
      };
    },
  };
</script>
