import setupElement from '@/setup/element-ui';
import setupClipboard from '@/setup/clipboard';
import './assets/css/public.css';
import './assets/css/theme.css';
import '@/icons';
import 'lib-flexible';
import 'babel-polyfill';

// 引入字体文件
import '@/assets/icon/iconfont.css';
import '@/assets/icon/iconfont.js';

import VueParticles from 'vue-particles';

import Vue from 'vue';
import App from './App';
import router from './router/index.js';
import store from '@/store';

Vue.use(VueParticles)
  .use(setupClipboard)
  .use(setupElement);

//使用钩子函数对路由进行权限跳转
router.beforeEach((to, from, next) => {
  const role = sessionStorage.getItem('userName');
  if (!role && to.path !== '/login') {
    next('/login');
  } else if (to.meta.permission) {
    console.log(to.meta, 'to');
    // 如果是管理员权限则可进入，这里只是简单的模拟管理员权限而已
    role === 'admin' ? next() : next('/error/403');
  } else {
    // 简单的判断IE10及以下不进入富文本编辑器，该组件不兼容
    if (navigator.userAgent.indexOf('MSIE') > -1 && to.path === '/editor') {
      Vue.prototype.$alert(
        'vue-quill-editor组件不兼容IE10及以下浏览器，请使用更高版本的浏览器查看',
        '浏览器不兼容通知',
        {
          confirmButtonText: '确定',
        }
      );
    } else {
      next();
    }
  }
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
