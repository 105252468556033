<template>
  <div>
    <el-form :inline="true">
      <el-form-item label="Affiliate Ids" label-width="100px">
        <group-select
          v-model="filter.affiliateIds"
          :data-source="affiliatesGroupList"
          multiple
          :loading="affiliateLoading"
          clearable
          reserve-keyword
          filterable
          placeholder="please select"
          style="width:4rem;"
        >
        </group-select>
      </el-form-item>
      <el-form-item label="Offer Ids" label-width="100px">
        <el-input v-model="filter.offerIds" placeholder="offerIds here..." />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="fetchTableDataList(1)">Search</el-button>
        <el-button type="primary" @click="addTrackSamplingClick">Add</el-button>
      </el-form-item>
    </el-form>
    <el-table
      border
      stripe
      style="width:100%;"
      height="60vmin"
      :data="trackSamplingList"
      highlight-current-row
    >
      <el-table-column label="OfferId" align="center">
        <template slot-scope="scope">
          <span class="cor337ab7" @click="toAffiliateDetailClick(scope.row)">{{
            scope.row.offerId
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="offerName" prop="offerName" align="center"></el-table-column>
      <el-table-column label="Affiliate Id" align="center">
        <template slot-scope="scope">
          <span class="cor337ab7" @click="toAffiliateDetailClick(scope.row)">{{
            scope.row.affiliateId
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Affiliate Name" prop="affiliateName" align="center"></el-table-column>
      <el-table-column label="Click Count" prop="clickCount" align="center"></el-table-column>
      <el-table-column label="Create By" prop="createBy" align="center"></el-table-column>
      <el-table-column label="Create Time" prop="createTime" align="center"></el-table-column>
      <el-table-column label="Action" align="center" width="160px">
        <template slot-scope="scope">
          <el-button
            style="margin-right: 4px;"
            size="small"
            type="primary"
            @click="editTrackSamplingClick(scope.row)"
          >
            Edit
          </el-button>
          <el-popconfirm
            title="Are you sure to delete this item?"
            @confirm="deleteTrackSampling(scope.row)"
          >
            <el-button slot="reference" size="small" type="danger">Delete</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      class="block pagePanel"
      :pageTotal="trackSamplingTotal"
      @handleSizeChange="handlePageSizeChange"
      @handleCurrentChange="handlePageCurrentChange"
      :pageSize="pageSize"
      :currentPage="currentPage"
    ></pagination>

    <el-dialog
      :title="modelType === 'edit' ? 'Edit Track Sampling' : 'Add Track Sampling'"
      :visible.sync="dialogVisible"
      width="50%"
    >
      <el-form :model="trackSampling" :rules="rules" ref="trackSamplingForm" label-width="100px">
        <el-row>
          <el-col :lg="11" :md="20">
            <el-form-item label="Offer Id" prop="offerId">
              <el-input v-model="trackSampling.offerId" />
            </el-form-item>
          </el-col>
          <el-col :lg="12" :md="20" :offset="1">
            <el-form-item label="Affiliate Id" prop="affiliateId">
              <group-select
                v-model="trackSampling.affiliateId"
                :data-source="affiliatesGroupList"
                :loading="affiliateLoading"
                clearable
                reserve-keyword
                filterable
                placeholder="请选择"
                style="width:4rem;"
              >
              </group-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :lg="11" :md="20">
            <el-form-item label="Click Count" prop="clickCount">
              <el-input-number v-model="trackSampling.clickCount" :min="1" :max="1000000" />
            </el-form-item>
          </el-col>
          <el-col :lg="11" :md="20" :offset="1">
            <el-form-item label="Status" prop="enable">
              <el-radio-group v-model="trackSampling.enable">
                <el-radio-button :label="0">关</el-radio-button>
                <el-radio-button :label="1">开</el-radio-button>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :lg="11" :md="20">
            <el-form-item label="Description" prop="description">
              <el-input v-model="trackSampling.description" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">Cancel</el-button>
        <el-button type="primary" :loading="submitLoading" @click="submitTrackSampling">
          Submit
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import {
    getTrackSamplingList,
    addTrackSampling,
    updateTrackSampling,
    deleteTrackSampling,
  } from 'api/affiliate/detail';
  import GroupSelect from '@/components/GroupSelect';
  import Pagination from '@/components/pagination';
  import { mapActions, mapState } from 'vuex';

  let clickTimer = null;
  export default {
    components: {
      Pagination,
      GroupSelect,
    },
    props: {
      id: {
        type: [String, Number],
        required: true,
      },
    },
    data() {
      return {
        filter: {
          offerIds: '',
          affiliateIds: [],
        },
        pageSize: 10,
        currentPage: 1,
        trackSamplingList: [],
        trackSamplingTotal: 0,
        trackSampling: {
          offerId: '',
          affiliateId: [],
          clickCount: 1,
          enable: 0,
          description: '',
        },
        rules: {
          offerId: [{ required: true, message: 'Please select offerId', trigger: 'blur' }],
          affiliateId: [{ required: true, message: 'Please select affiliateId', trigger: 'blur' }],
          clickCount: [{ required: true, message: 'Please select clickCount', trigger: 'blur' }],
          createBy: [{ required: true, message: 'Please input createBy', trigger: 'blur' }],
        },
        dialogVisible: false,
        modelType: 'add',
        submitLoading: false,
      };
    },
    computed: {
      ...mapState('affiliate', {
        affiliatesGroupList: (state) => state.affiliatesGroupList,
        affiliateLoading: (state) => state.affiliateLoading,
      }),
    },
    mounted() {
      this.fetchTableDataList();
      this.getAffiliateList();
    },
    methods: {
      ...mapActions('affiliate', ['getAffiliateList']),
      fetchTableDataList(curPage) {
        if (curPage) {
          this.currentPage = curPage;
        }
        const param = {
          sourceId: this.id,
          page: this.currentPage,
          pageSize: this.pageSize,
        };
        if (this.filter.offerIds) {
          param.offerIds = this.filter.offerIds;
        }

        if (this.filter.affiliateIds.length > 0) {
          param.affiliateIds = this.filter.affiliateIds.join(',');
        }

        getTrackSamplingList(param)
          .then((response) => {
            if (response.code === 200) {
              this.trackSamplingList = response.result;
              this.trackSamplingTotal = response.total;
            }
          })
          .catch((error) => {
            this.$message.error(error.message);
            this.trackSamplingList = [];
            this.trackSamplingTotal = 0;
          });
      },
      toAffiliateDetailClick(row) {
        if (clickTimer) {
          window.clearTimeout(clickTimer);
          clickTimer = null;
        }
        var that = this;
        clickTimer = window.setTimeout(function() {
          that.$nextTick(() => {
            that.$router.push({
              path: '/affiliate/detail',
              query: {
                affiliateId: row.affiliateId,
              },
            });
          });
        }, 300);
      },
      handlePageSizeChange(val) {
        this.pageSize = val;
        this.handlePageCurrentChange(1);
      },
      handlePageCurrentChange(val) {
        this.currentPage = val;
        this.fetchTableDataList();
      },
      addTrackSamplingClick() {
        this.modelType = 'add';
        this.dialogVisible = true;
        this.trackSampling = {
          offerId: '',
          affiliateId: [],
          clickCount: 1,
          enable: 0,
          description: '',
        };
      },
      /**
       * 提交 Track Sampling 表单
       * 处理新增和编辑两种场景
       */
      async submitTrackSampling() {
        try {
          // 表单验证
          const valid = await this.$refs.trackSamplingForm.validate();
          if (!valid) {
            return;
          }
          this.submitLoading = true;
          const params = {
            ...this.trackSampling,
            sourceId: this.id,
          };

          // 根据模式选择API
          const apiMethod = this.modelType === 'add' ? addTrackSampling : updateTrackSampling;
          const actionName = this.modelType === 'add' ? 'Add' : 'Update';

          const response = await apiMethod(params);

          if (response.code === 200) {
            this.$message.success(`${actionName} Success`);
            this.dialogVisible = false;
            this.fetchTableDataList();
          } else {
            throw new Error(response.message || `${actionName} failed`);
          }
        } catch (error) {
          console.error(error);
        } finally {
          this.submitLoading = false;
        }
      },
      editTrackSamplingClick(row) {
        this.modelType = 'edit';
        this.dialogVisible = true;
        this.trackSampling = {
          ...row,
        };
        this.$nextTick(() => {
          this.$refs.trackSamplingForm.validate();
        });
      },
      async deleteTrackSampling(row) {
        try {
          await deleteTrackSampling({
            operationIds: [row.id],
            sourceId: this.id,
          });
          this.$message.success('Delete Success');
          this.fetchTableDataList();
        } catch (error) {
          this.$message.error(error);
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .cor337ab7 {
    color: #337ab7;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
</style>
