import { getMenuList } from 'api/user';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

const state = {
  menuList: [], //侧边菜单列表
  tagsList: [], //打开的标签页个数,
  breadList: ['Dashboard'], //面包屑导航
  isCollapse: false, //侧边导航是否折叠
  theme: 'default',
  fingerprint: '',
};
const getters = {
  browserHeaderTitle: (state) => state.browserHeaderTitle,
  getTheme: (state) => state.theme,
};
const mutations = {
  SET_MENU_LIST: (state, menuList) => {
    state.menuList = menuList;
  },
  //保存标签
  TAGES_LIST(state, arr) {
    state.tagsList = arr;
  },
  SET_BREAD_LIST(state, breadList) {
    state.breadList = breadList;
  },
  IS_COLLAPSE(state, bool) {
    state.isCollapse = bool;
  },
  SET_BROWSERHEADERTITLE: (state, action) => {
    state.browserHeaderTitle = action.browserHeaderTitle;
  },
  SET_THEME: (state, theme) => {
    state.theme = theme;
  },
  SET_FINGERPRINT: (state, fingerprint) => {
    state.fingerprint = fingerprint;
  },
};
const actions = {
  getMenuList({ commit }, flag) {
    return new Promise((resolve, reject) => {
      getMenuList()
        .then((res) => {
          const { result, code } = res;
          if (code === 200) {
            commit('SET_MENU_LIST', result);
            if (flag) {
              commit('SET_BREAD_LIST', [result[0].title]);
            }
            resolve(result);
          } else {
            reject();
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  setTheme({ commit }) {
    const url = window?.location.href ?? '';
    if (url.includes('gourdmobi')) {
      commit('SET_THEME', 'gourdmobi');
    } else if (url.includes('bananamobiads')) {
      commit('SET_THEME', 'bananamobiads');
    } else if (url.includes('jintu')) {
      commit('SET_THEME', 'jintu');
    } else {
      commit('SET_THEME', 'default');
    }
  },
  async generateFingerprint({ commit }) {
    const fpPromise = FingerprintJS.load();
    const fp = await fpPromise;
    const result = await fp.get();
    /* eslint-disable */
    const {
      screenFrame,
      screenResolution,
      colorGamut,
      colorDepth,
      hdr,
      plugins,
      fonts,
      ...components
    } = result.components;
    const visitorId = FingerprintJS.hashComponents({
      ...components,
    });
    commit('SET_FINGERPRINT', visitorId);
  },
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
