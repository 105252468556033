<template>
  <div class="trackSamplingReport">
    <div class="title">
      <h2>Track Sampling Report</h2>
    </div>
    <div class="bgf0">
      <el-row type="flex">
        <el-col :span="24" style="padding:16px;">
          <el-card shadow="hover" class="pad30">
            <el-form v-model="trackSamplingReport" :inline="true">
              <el-row :gutter="24">
                <el-col :xs="24" :sm="2" :md="2" :lg="2" :xl="2">
                  <label class="labelName">Offer(s)</label>
                </el-col>
                <el-form-item label="OfferId(s)" label-width="1.8rem">
                  <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                    <el-input
                      v-model="trackSamplingReport.offerIds"
                      placeholder="offerIds: eg. 2206264 or 2206264,1567396"
                    ></el-input>
                  </el-col>
                </el-form-item>
                <el-form-item>
                  <el-col :xs="1" :sm="1" :md="1" :lg="1" :xl="1">
                    <el-checkbox v-model="offerChecked"></el-checkbox>
                  </el-col>
                </el-form-item>
              </el-row>
              <el-row :gutter="24">
                <el-col :xs="24" :sm="2" :md="2" :lg="2" :xl="2">
                  <label class="labelName">Affiliate(s)</label>
                </el-col>
                <el-form-item label="AffiliateId(s)" label-width="2.08rem">
                  <el-col :xs="24" :sm="20" :md="20" :lg="20" :xl="20">
                    <group-select
                      v-model="trackSamplingReport.affiliateIds"
                      :data-source="affiliatesGroupList"
                      multiple
                      :loading="affiliateLoading"
                      clearable
                      reserve-keyword
                      filterable
                      placeholder="请选择"
                    />
                  </el-col>
                </el-form-item>
                <el-form-item>
                  <el-col :xs="1" :sm="1" :md="1" :lg="1" :xl="1">
                    <el-checkbox v-model="affiliateChecked"></el-checkbox>
                  </el-col>
                </el-form-item>
              </el-row>
              <el-row :gutter="24">
                <el-col :xs="24" :sm="2" :md="2" :lg="2" :xl="2">
                  <label class="labelName">Source(s)</label>
                </el-col>
                <el-form-item label="Source(s)" label-width="1.8rem">
                  <el-col :xs="24" :sm="20" :md="20" :lg="20" :xl="20">
                    <group-select
                      v-model="trackSamplingReport.sourceIds"
                      :data-source="sourcesList"
                      multiple
                      :loading="sourceLoading"
                      reserve-keyword
                      clearable
                      filterable
                      placeholder="请选择"
                      style="width:4.4rem;"
                    />
                  </el-col>
                </el-form-item>
                <el-form-item>
                  <el-col :xs="1" :sm="1" :md="1" :lg="1" :xl="1">
                    <el-checkbox v-model="sourceChecked"></el-checkbox>
                  </el-col>
                </el-form-item>
              </el-row>
              <el-row>
                <label class="labelName" style="margin-right:20px;">TimeZone</label>
                <el-form-item>
                  <el-select v-model="trackSamplingReport.timezone" style="width:420px;">
                    <el-option
                      v-for="item in timezoneOption"
                      :key="item.value"
                      :value="item.number"
                      :label="item.label"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-row>
              <el-row>
                <el-col :lg="3" :md="3" :xs="3" :sm="3" :xl="3">
                  <label class="labelName">Interval</label>
                </el-col>
                <el-col :lg="6" :md="6" :xs="6" :sm="6" :xl="6">
                  <el-form-item label="Month">
                    <el-checkbox v-model="monthChecked"></el-checkbox>
                  </el-form-item>
                </el-col>
                <el-col :lg="6" :md="6" :xs="6" :sm="6" :xl="6">
                  <el-form-item label="Date">
                    <el-checkbox v-model="dateChecked"></el-checkbox>
                  </el-form-item>
                </el-col>
                <el-col :lg="6" :md="6" :xs="6" :sm="6" :xl="6">
                  <el-form-item label="Hour">
                    <el-checkbox v-model="hourChecked"></el-checkbox>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <label class="labelName" style="margin-right:60px;">Date</label>
                <el-form-item>
                  <el-date-picker
                    v-model="startToEndDate"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    type="daterange"
                    style="width:260px;"
                    align="right"
                    unlink-panels
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :picker-options="pickerOptions"
                  ></el-date-picker>
                </el-form-item>
              </el-row>
              <el-row>
                <el-col :lg="3" :md="20" :offset="1">
                  <el-form-item>
                    <el-button type="primary" @click="searchListClick(1)">In Browser</el-button>
                  </el-form-item>
                </el-col>
                <el-col :lg="3" :md="20">
                  <el-form-item>
                    <el-button type="primary" @click="exportExcelFileClick">
                      Download Excel
                    </el-button>
                  </el-form-item>
                </el-col>
                <el-col :lg="3" :md="20" :offset="1">
                  <el-form-item>
                    <el-button type="primary" @click="openSendPBDialog">Send PB</el-button>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </el-card>
        </el-col>
      </el-row>
      <el-card shadow="hover">
        <el-table
          v-loading="listLoading"
          ref="tableRef"
          style="width:100%;"
          height="70vmin"
          stripe
          border
          :data="trackSamplingReportList"
          highlight-current-row
        >
          <el-table-column type="selection" width="55" v-if="offerChecked"> </el-table-column>
          <el-table-column
            label="Date"
            prop="date"
            align="center"
            :fixed="fullWidth <= 768 ? false : 'left'"
            key="date"
          ></el-table-column>
          <el-table-column
            label="Affiliate ID"
            align="center"
            :fixed="fullWidth <= 768 ? false : 'left'"
            v-if="affiliateChecked"
            key="affiliateId"
          >
            <template slot-scope="scope">
              <span class="cor337ab7" @click="affiliatetailClick(scope.row)">{{
                scope.row.affiliateId
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="Affiliate Name"
            width="140"
            :fixed="fullWidth <= 768 ? false : 'left'"
            v-if="affiliateChecked"
            align="center"
            key="affiliateName"
          >
            <template slot-scope="scope">
              <span class="cor337ab7" @click="affiliatetailClick(scope.row)">{{
                scope.row.affiliateName
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="Source ID" key="sourceId" v-if="sourceChecked" align="center">
            <template slot-scope="scope">
              <span class="cor337ab7" @click="sourcedetailClick(scope.row)">{{
                scope.row.sourceId
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="Source Name"
            prop="sourceName"
            v-if="sourceChecked"
            align="center"
            key="sourceName"
          >
            <template slot-scope="scope">
              <span class="cor337ab7" @click="sourcedetailClick(scope.row)">{{
                scope.row.sourceName
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="Offer ID" v-if="offerChecked" key="offerId" align="center">
            <template slot-scope="scope">
              <span class="cor337ab7" @click="offerdetailClick(scope.row)">{{
                scope.row.offerId
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="Offer Name" v-if="offerChecked" align="center">
            <template slot-scope="scope">
              <span class="cor337ab7" @click="offerdetailClick(scope.row)">{{
                scope.row.offerName
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="Click Count"
            prop="clickCount"
            align="center"
            key="clickCount"
          ></el-table-column>
          <el-table-column
            label="Aff Conversion Count"
            prop="affConversionCount"
            align="center"
            key="affConversionCount"
          ></el-table-column>

          <el-table-column label="totalPayout" align="center" prop="totalPayout" key="totalPayout">
          </el-table-column>
        </el-table>
        <pagination
          class="block pagePanel"
          :pageTotal="total"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
          :pageSize="pageParam.pageSize"
          :currentPage="pageParam.page"
        ></pagination>
      </el-card>
    </div>
    <el-dialog
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :append-to-body="true"
      title="Send PB"
      width="600px"
    >
      <el-form :model="sendAffPbForm" label-width="110px" :rules="rules" ref="sendAffPbForm">
        <el-form-item label="Date" prop="date">
          <el-date-picker
            v-model="sendAffPbForm.date"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            type="daterange"
            style="width:260px;"
            align="right"
            unlink-panels
            range-separator="to"
            start-placeholder="start date"
            end-placeholder="end date"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="Source ID(s)">
          <group-select
            v-model="sendAffPbForm.sourceIds"
            :data-source="sourcesList"
            multiple
            :loading="sourceLoading"
            clearable
            reserve-keyword
            filterable
            placeholder="please select sourceIds"
          />
        </el-form-item>
        <el-form-item label="Affiliate ID(s)">
          <group-select
            v-model="sendAffPbForm.affiliateIds"
            :data-source="affiliatesGroupList"
            multiple
            :loading="affiliateLoading"
            clearable
            reserve-keyword
            filterable
            placeholder="please select affiliateIds"
          />
        </el-form-item>
        <el-form-item label="Offer ID(s)">
          <el-input
            v-model="sendAffPbForm.offerIds"
            style="width: 194px;"
            placeholder="please input offerIds"
          ></el-input>
        </el-form-item>
        <el-form-item label="Count" prop="count">
          <el-input-number v-model="sendAffPbForm.count" placeholder="please input count" />
        </el-form-item>
        <el-form-item label="Payout Value" prop="payoutValue">
          <el-input-number
            v-model="sendAffPbForm.payoutValue"
            placeholder="please input payoutValue"
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">Cancel</el-button>
        <el-button type="primary" :loading="submitLoading" @click="handlerSendPb">
          Send Pb
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import { api, getTrackSamplingReport, sendPbByTrackSampling } from 'api/report';
  import Pagination from '@/components/pagination';
  import GroupSelect from '@/components/GroupSelect';
  import { cloneDeep } from 'lodash-es';
  import dateUtils from '@/utils/dateutils.js';
  import qs from 'querystring';
  import { myMixin } from '@/mixins/mixins.js';
  import optionData from '@/assets/js/optionData';
  import { filterObject } from 'utils/utils';
  import { mapState, mapActions } from 'vuex';

  export default {
    mixins: [myMixin],
    components: {
      Pagination,
      GroupSelect,
    },
    data() {
      return {
        pageParam: {
          page: 1,
          pageSize: 20,
        },
        dataParam: null,
        offerChecked: false,
        affiliateChecked: true,
        sourceChecked: false,
        fullWidth: document.documentElement.clientWidth,
        monthChecked: false,
        dateChecked: true,
        hourChecked: true,
        startToEndDate: '',
        listLoading: false,
        total: 0,
        summariesMap: {},
        trackSamplingReportList: [],
        timezoneOption: optionData.timezoneOption,
        trackSamplingReport: {
          offerIds: null,
          affiliateIds: null,
          columns: [],
          fromDate: null,
          toDate: null,
          sourceIds: null,
          timezone: 0,
        },
        dialogVisible: false,
        submitLoading: false,
        sendAffPbForm: {
          sourceIds: [],
          affiliateIds: [],
          offerIds: '',
          date: [],
          timezone: 0,
          count: 0,
          payoutValue: 0,
        },
        rules: {
          date: [
            {
              required: true,
              message: 'Please select date',
              trigger: 'change',
              type: 'array',
            },
          ],
          count: [
            {
              required: true,
              type: 'number',
              message: 'Please input count',
              trigger: 'blur',
            },
          ],
          payoutValue: [
            {
              required: true,
              type: 'number',
              message: 'Please input payoutValue',
              trigger: 'blur',
            },
          ],
        },
      };
    },
    mounted() {
      //默认选择
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() + 3600 * 1000 * 24);
      this.startToEndDate = [];
      this.startToEndDate[0] = dateUtils.date2StrYMD(end);
      this.startToEndDate[1] = dateUtils.date2StrYMD(start);

      this.getAffiliateList();
      this.getSourceIdsList();
    },
    computed: {
      ...mapState('affiliate', {
        affiliatesGroupList: (state) => state.affiliatesGroupList,
        affiliateLoading: (state) => state.affiliateLoading,
      }),
      ...mapState('source', {
        sourcesList: (state) => state.sourcesList,
        sourceLoading: (state) => state.sourceLoading,
      }),
      listenChange() {
        const { affiliateChecked, sourceChecked, offerChecked } = this;
        return {
          affiliateChecked,
          sourceChecked,
          offerChecked,
        };
      },
    },
    watch: {
      listenChange: function() {
        this.$nextTick(() => {
          this.$refs.tableRef.doLayout();
        });
      },
    },
    methods: {
      ...mapActions('affiliate', ['getAffiliateList']),
      ...mapActions('source', ['getSourceIdsList']),
      searchListClick(curPage) {
        if (curPage) {
          this.pageParam.page = curPage;
        }
        let param = {
          ...this.trackSamplingReport,
          ...this.pageParam,
        };
        if (Array.isArray(param.sourceIds) && param.sourceIds.length > 0) {
          param.sourceIds = param.sourceIds.join(',');
        } else {
          delete param.sourceIds;
        }
        if (Array.isArray(param.affiliateIds) && param.affiliateIds.length > 0) {
          param.affiliateIds = param.affiliateIds.join(',');
        } else {
          delete param.affiliateIds;
        }

        if (this.offerChecked == true && param.columns.indexOf('offer_id') == -1) {
          param.columns.push('offer_id');
        } else if (this.offerChecked == false && param.columns.indexOf('offer_id') !== -1) {
          const offerIdIndex = param.columns.findIndex((item) => {
            if (item == 'offer_id') {
              return true;
            }
          });
          param.columns.splice(offerIdIndex, 1);
        }

        if (this.affiliateChecked == true && param.columns.indexOf('affiliate_id') == -1) {
          param.columns.push('affiliate_id');
        } else if (this.affiliateChecked == false && param.columns.indexOf('affiliate_id') !== -1) {
          const affiliateIndex = param.columns.findIndex((item) => {
            if (item == 'affiliate_id') {
              return true;
            }
          });
          param.columns.splice(affiliateIndex, 1);
        }

        if (
          this.sourceChecked == true &&
          param.columns.indexOf('source_id') == -1 &&
          param.columns.indexOf('source_name') == -1
        ) {
          param.columns.push('source_id');
          param.columns.push('source_name');
        } else if (
          this.sourceChecked == false &&
          param.columns.indexOf('source_id') !== -1 &&
          param.columns.indexOf('source_name') !== -1
        ) {
          const sourceIdIndex = param.columns.findIndex((item) => {
            if (item == 'source_id') {
              return true;
            }
          });
          param.columns.splice(sourceIdIndex, 1);

          const sourceNameIndex = param.columns.findIndex((item) => {
            if (item == 'source_name') {
              return true;
            }
          });
          param.columns.splice(sourceNameIndex, 1);
        }

        if (this.monthChecked == true && param.columns.indexOf('month') == -1) {
          param.columns.push('month');
        } else if (this.monthChecked == false && param.columns.indexOf('month') !== -1) {
          const monthIndex = param.columns.findIndex((item) => {
            if (item == 'month') {
              return true;
            }
          });
          param.columns.splice(monthIndex, 1);
        }

        if (this.dateChecked == true && param.columns.indexOf('date') == -1) {
          param.columns.push('date');
        } else if (this.dateChecked == false && param.columns.indexOf('date') !== -1) {
          const dateIndex = param.columns.findIndex((item) => {
            if (item == 'date') {
              return true;
            }
          });
          param.columns.splice(dateIndex, 1);
        }

        if (this.hourChecked == true && param.columns.indexOf('hour') == -1) {
          param.columns.push('hour');
        } else if (this.hourChecked == false && param.columns.indexOf('hour') !== -1) {
          const hourIndex = param.columns.findIndex((item) => {
            if (item == 'hour') {
              return true;
            }
          });
          param.columns.splice(hourIndex, 1);
        }

        if (this.startToEndDate.length === 2) {
          let startDate = this.startToEndDate[0];
          let endDate = this.startToEndDate[1];
          param.fromDate = startDate;
          param.toDate = endDate;
        }
        this.pageParam.page = 1;

        const dataParam = cloneDeep(param);
        if (Array.isArray(dataParam.columns)) {
          dataParam.columns = dataParam.columns.join(',');
        }
        this.dataParam = dataParam;

        this.trackSamplingReportApi();
      },
      trackSamplingReportApi() {
        this.listLoading = true;
        let param = {
          ...this.dataParam,
          ...this.pageParam,
        };
        param = filterObject(param);
        getTrackSamplingReport(param).then((response) => {
          this.listLoading = false;
          if (response.code === 200) {
            const result = response.result || [];
            this.trackSamplingReportList = result;
            this.summariesMap = result?.pop();
            this.total = result?.total ?? 0;
            if (this.summariesMap) {
              const totalObject = {
                ...this.summariesMap,
              };
              totalObject.date = 'Total';
              this.trackSamplingReportList.push(totalObject);
            }
          } else {
            this.trackSamplingReportList = [];
            this.summariesMap = {};
            this.total = null;
            this.$message.error(response.message);
          }
        });
      },
      exportExcelFileClick() {
        let param = {};
        let columns = new Array();

        if (this.affiliateChecked == true && columns.indexOf('affiliate_id') == -1) {
          columns.push('affiliate_id');
        } else if (this.affiliateChecked == false && columns.indexOf('affiliate_id') !== -1) {
          const affiliateIndex = columns.findIndex((item) => {
            if (item == 'affiliate_id') {
              return true;
            }
          });
          columns.splice(affiliateIndex, 1);
        }

        if (
          this.sourceChecked == true &&
          columns.indexOf('source_id') == -1 &&
          columns.indexOf('source_name') == -1
        ) {
          columns.push('source_id');
          columns.push('source_name');
        } else if (
          this.sourceChecked == false &&
          columns.indexOf('source_id') !== -1 &&
          columns.indexOf('source_name') !== -1
        ) {
          const sourceIdIndex = columns.findIndex((item) => {
            if (item == 'source_id') {
              return true;
            }
          });
          columns.splice(sourceIdIndex, 1);

          const sourceNameIndex = columns.findIndex((item) => {
            if (item == 'source_name') {
              return true;
            }
          });
          columns.splice(sourceNameIndex, 1);
        }

        if (this.offerChecked == true && columns.indexOf('offer_id') == -1) {
          columns.push('offer_id');
        } else if (this.offerChecked == false && columns.indexOf('offer_id') !== -1) {
          const offerIdIndex = columns.findIndex((item) => {
            if (item == 'offer_id') {
              return true;
            }
          });
          columns.splice(offerIdIndex, 1);
        }

        if (this.monthChecked == true && columns.indexOf('month') == -1) {
          columns.push('month');
        } else if (this.monthChecked == false && columns.indexOf('month') !== -1) {
          const monthIndex = columns.findIndex((item) => {
            if (item == 'month') {
              return true;
            }
          });
          columns.splice(monthIndex, 1);
        }

        if (this.dateChecked == true && columns.indexOf('date') == -1) {
          columns.push('date');
        } else if (this.dateChecked == false && columns.indexOf('date') !== -1) {
          const dateIndex = columns.findIndex((item) => {
            if (item == 'date') {
              return true;
            }
          });
          columns.splice(dateIndex, 1);
        }

        if (this.hourChecked == true && columns.indexOf('hour') == -1) {
          columns.push('hour');
        } else if (this.hourChecked == false && columns.indexOf('hour') !== -1) {
          const hourIndex = columns.findIndex((item) => {
            if (item == 'hour') {
              return true;
            }
          });
          columns.splice(hourIndex, 1);
        }

        param['fromDate'] = this.startToEndDate[0];
        param['toDate'] = this.startToEndDate[1];
        if (this.trackSamplingReport.timezone != null) {
          param['timezone'] = this.trackSamplingReport.timezone;
        }

        if (
          this.trackSamplingReport.sourceIds != null &&
          this.trackSamplingReport.sourceIds.length > 0
        ) {
          param['sourceIds'] = this.trackSamplingReport.sourceIds + '';
        }
        if (this.trackSamplingReport.affiliateIds != null) {
          param['affiliateIds'] = this.trackSamplingReport.affiliateIds + '';
        }
        if (this.trackSamplingReport.offerIds != null) {
          param['offerIds'] = this.trackSamplingReport.offerIds;
        }
        if (columns != null) {
          param['columns'] = columns.join(',');
        }
        param = filterObject(param);
        let strParam = qs.stringify(param);

        window.open(`${api.DOWNLOAD_TRACK_SAMPLING_REPORT}?${strParam}`, '_blank');
      },
      handleSizeChange(size) {
        this.pageParam.pageSize = size;
        this.handleCurrentChange(1);
      },
      handleCurrentChange(currentPage) {
        this.pageParam.page = currentPage;
        this.trackSamplingReportApi();
      },
      affiliatetailClick(row) {
        const { href } = this.$router.resolve({
          path: '/affiliate/detail',
          query: {
            affiliateId: row.affiliateId,
          },
        });
        window.open(href, '_blank');
      },
      sourcedetailClick(row) {
        const { href } = this.$router.resolve({
          path: '/source/detail',
          query: {
            sourceId: row.sourceId,
          },
        });
        window.open(href, '_blank');
      },
      offerdetailClick(row) {
        const { href } = this.$router.resolve({
          path: '/offer/detail',
          query: {
            offerId: row.offerId,
          },
        });
        window.open(href, '_blank');
      },
      openSendPBDialog() {
        this.dialogVisible = true;
        this.sendAffPbForm = {
          affiliateIds: [],
          offerIds: '',
          sourceIds: [],
          date: [dateUtils.date2StrYMD(new Date()), dateUtils.date2StrYMD(new Date())],
          timezone: 0,
          count: 0,
          payoutValue: 0,
        };
      },
      async handlerSendPb() {
        try {
          this.submitLoading = true;
          await this.$refs.sendAffPbForm.validate();
          const res = await sendPbByTrackSampling(this.sendAffPbForm);
          if (res.code === 200) {
            this.dialogVisible = false;
            this.$message.success('Send PB Success');
          } else {
            this.$message.error(res.message);
          }
        } catch (error) {
          console.error(error);
        } finally {
          this.submitLoading = false;
        }
      },
    },
  };
</script>
<style lang="scss" scoped>
  .black-dialog {
    width: 400px;
    p {
      line-height: 30px;
    }
    input {
      width: 300px;
    }
  }
</style>
<style lang="scss"></style>
