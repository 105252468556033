import request from 'utils/request';

export const api = {
  // adv report
  ADV_REPORT: '/api/report/adv/list',
  DOWNLOAD_ADV_REPORT: '/api/report/adv/downloadExcel',
  // bill report
  BILL_REPORT: '/api/report/aff/list',
  DOWNLOAD_BILL_REPORT: '/api/report/aff/downloadExcel',
  HISTORY_DEDUCT: '/api/report/adv/downloadExcel',
  // postback report
  POSTBACK_REPORT: '/api/report/postback/list',
  DOWNLOAD_POSTBACK_REPORT: '/api/report/postback/download',

  // commit report
  COMMIT_REPORT: '/api/report/common/list',
  DOWNLOAD_COMMIT_REPORT: '/api/report/common/export',

  // conversion report
  CONVERSION_REPORT: '/api/report/conversion/list',
  DOWNLOAD_CONVERSION_REPORT: '/api/report/conversion/export',
  SEND_AFF_PB_CONVERSION_REPORT: '/api/report/conversion/sendAffPb',

  // click mix report
  MIX_REPORT_LIST: '/api/report/mix/list',
  MIX_CONFIG_LIST: '/api/report/mix/setting/list',
  ADD_MIX_CONFIG: '/api/report/mix/setting/add',
  DELETE_MIX_CONFIG: '/api/report/mix/setting/delete',

  DEFAULT_TRAFFIC_LIST: '/api/report/default/list',

  ADD_OFFER_BLACK: '/api/affiliate/overall/common/offerBlack/add',

  PID_REPORT_INFO: '/api/report/pid/list',
  DOWNLOAD_PID_REPORT: '/api/report/pid/export',

  // Event Report
  EVENT_REPORT_INFO: '/api/report/event/list',
  DOWNLOAD_EVENT_REPORT: '/api/report/event/downloadExcel',

  // Simulate Report
  SIMULATE_REPORT_IFNO: '/api/report/simulate/list',

  // Reject Report
  REJECT_REPORT: '/api/report/reject/list',
  DOWNLOAD_REJECT_REPORT_DETAIL: '/api/report/reject/detailDownload',
  DOWNLOAD_REJECT_REPORT: '/api/report/reject/download',

  // Direct Report
  DIRECT_REPORT: '/api/report/direct/list',
  DOWNLOAD_DIRECT_REPORT_DETAIL: ' /api/report/direct/downloadExcel',
  AM_LIST: '/api/report/direct/userInfo',

  //  Smart Tracking Report
  SMART_TRACKING_REPORT: '/api/report/smart/list',

  // Track Sampling Report
  TRACK_SAMPLING_REPORT: '/api/report/track/sampling/list',
  DOWNLOAD_TRACK_SAMPLING_REPORT: '/api/report/track/sampling/export',
  SEND_PB_BY_TRACK_SAMPLING: '/api/report/track/sampling/sendPb',
};

// adv report
export function getAdvReport(query) {
  return request({
    url: api.ADV_REPORT,
    method: 'get',
    params: query,
  });
}

// bill report
export function getBillReport(query) {
  return request({
    url: api.BILL_REPORT,
    method: 'get',
    params: query,
    timeout: 180000,
  });
}

export function getHistoryDeduct(data) {
  return request({
    url: api.HISTORY_DEDUCT,
    method: 'post',
    data,
  });
}

// postback report
export function getPostbackReport(query) {
  return request({
    url: api.POSTBACK_REPORT,
    method: 'get',
    params: query,
  });
}

// commit report
export function getCommonReport(query) {
  return request({
    url: api.COMMIT_REPORT,
    method: 'get',
    params: query,
    timeout: 300 * 1000,
  });
}

// coversion report
export function getConversionReport(query) {
  return request({
    url: api.CONVERSION_REPORT,
    method: 'get',
    params: query,
  });
}

export function sendAffPb(params) {
  return request({
    url: api.SEND_AFF_PB_CONVERSION_REPORT,
    method: 'put',
    data: params,
  });
}

// click mix report
export function getMixReportList(query) {
  return request({
    url: api.MIX_REPORT_LIST,
    method: 'get',
    params: query,
  });
}

export function getMixConfigList(query) {
  return request({
    url: api.MIX_CONFIG_LIST,
    method: 'get',
    params: query,
  });
}

export function addMixConfig(data) {
  return request({
    url: api.ADD_MIX_CONFIG,
    method: 'post',
    data,
  });
}

export function deleteConfig(ids) {
  return request({
    url: api.DELETE_MIX_CONFIG,
    method: 'delete',
    params: {
      ids,
    },
  });
}

//
export function getDefaultTrafficList(query) {
  return request({
    url: api.DEFAULT_TRAFFIC_LIST,
    method: 'get',
    params: query,
  });
}

export function addOfferBlack(data) {
  return request({
    url: api.ADD_OFFER_BLACK,
    method: 'post',
    data,
  });
}

export function getPidReport(data) {
  return request({
    url: api.PID_REPORT_INFO,
    method: 'post',
    data,
  });
}

export function exportPidReport(data) {
  return request({
    url: api.DOWNLOAD_PID_REPORT,
    method: 'post',
    data,
  });
}

export function getEventReport(query) {
  return request({
    url: api.EVENT_REPORT_INFO,
    method: 'get',
    params: query,
  });
}

export function getSimulateReport(query) {
  return request({
    url: api.SIMULATE_REPORT_IFNO,
    method: 'get',
    params: query,
  });
}

export function getRejectReport(query) {
  return request({
    url: api.REJECT_REPORT,
    method: 'get',
    params: query,
  });
}

export function getAmListApi() {
  return request({
    url: api.AM_LIST,
    method: 'get',
  });
}

export function getDirectReport(query) {
  return request({
    url: api.DIRECT_REPORT,
    method: 'get',
    params: query,
  });
}

export function getSmartTrackingReport(query) {
  return request({
    url: api.SMART_TRACKING_REPORT,
    method: 'get',
    params: query,
  });
}

// Track Sampling Report
export function getTrackSamplingReport(query) {
  return request({
    url: api.TRACK_SAMPLING_REPORT,
    method: 'get',
    params: query,
  });
}

// Track Sampling 渠道PB下发接口
export function sendPbByTrackSampling(data) {
  return request({
    url: api.SEND_PB_BY_TRACK_SAMPLING,
    method: 'post',
    data,
  });
}
